<template>
  <v-list-item v-if="showAll || item.status !== 'processed'">
    <v-list-item-avatar v-if="item.action">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-if="item.action === 'add'" v-on="on" color="success">fal fa-plus-circle</v-icon>
          <v-icon v-else-if="item.action === 'drop'" v-on="on" color="error">fal fa-times-circle</v-icon>
          <v-icon v-else-if="item.action === 'change'" v-on="on" color="info">fal fa-info-circle</v-icon>
        </template>
        <span v-if="item.action === 'add'">Added to schedule</span>
        <span v-else-if="item.action === 'drop'">Removed from schedule</span>
        <span v-else-if="item.action === 'change'">Grade mode changed</span>
      </v-tooltip>
    </v-list-item-avatar>
    <v-list-item-content :style="item.removed || ['DC', 'DD'].includes(item.regStatus) ? 'text-decoration:line-through' : ''">
      <v-list-item-title>
        <v-icon v-if="item.regStatus === 'WL'" color="info" class="mr-3" style="transform:translateY(5px)">fas fa-pause-circle</v-icon>
        <v-icon v-else-if="['DC', 'DD'].includes(item.regStatus)" color="error" class="mr-3" style="transform:translateY(5px)">fas fa-times-circle</v-icon>
        {{ item.title }}
        <span v-if="item.credits">({{ item.credits }} Credits)</span><br/>
        <v-list-item-subtitle v-if="item.regStatus === 'WL'" class="ml-10" style="font-style:italic">**You are currently wait-listed for this course**</v-list-item-subtitle>
        <v-list-item-subtitle v-if="['DC', 'DD'].includes(item.regStatus)" class="ml-10" style="font-style:italic">**You are no longer registered for this course**</v-list-item-subtitle>
      </v-list-item-title>
      <v-list-item-subtitle>{{ item.instructorName }} <span v-if="item.instructorEmail">({{ item.instructorEmail }})</span></v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.action === 'add'">Course added to schedule</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="item.action === 'drop'">Course removed from schedule</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="item.action === 'change'">Course grade mode changed to {{ item.gradeMode === 'grade' ? 'standard grading' : 'pass/fail' }}</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="item.times">{{ item.times }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="admin && isRecords && 'approval' in item && item.action === 'drop' && item.approval.grade">Grade Assigned: {{ item.approval.grade }}</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="item.action === 'add' && item.gradeMode">Course grading mode: {{ item.gradeMode === 'grade' ? 'standard grading' : 'pass/fail' }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="item.status === 'approved' || item.status === 'processed'">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="success" style="margin-right:8px">fal fa-check-circle</v-icon>
        </template>
        <span>Change request has been approved and {{ item.status === 'processed' ? 'has been processed' : 'and is pending processing' }} by the Records office.</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="!admin && !item._id && isUpperClassman && !item.action">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="$emit('change-mode')" icon>
            <v-icon color="primary">fal fa-edit</v-icon>
          </v-btn>
        </template>
        <span>
          <div>Change Grade Mode</div>
          <div v-if="'gradeMode' in item" style="font-size:.8em">(Currently {{ item.gradeMode === 'grade' ? 'standard grading' : 'pass/fail' }})</div>
        </span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="icon">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-if="action && actionable" v-on="on" :color="color" @click="$emit(action)">
            <v-icon left>{{ icon }}</v-icon>
            {{ actionText }}
          </v-btn>
          <v-icon v-else v-on="on" :color="color">{{ icon }}</v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    approver: {
      type: Boolean,
      default: false
    },
    isUpperClassman: {
      type: Boolean,
      default: false
    },
    actionable: {
      type: Boolean,
      default: true
    },
    showAll: {
      type: Boolean,
      default: false
    },
    requireGrade: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const isRecords = computed(() => 'Records' in roles.value)

    const icon = computed(() => {
      let { admin, approver, item: { status, action } } = props
      if (action === false) return false
      // if (status === 'approved' || status === 'processed') return 'fal fa-check'
      if (status === 'pending') {
        if (admin) {
          if (approver) {
            return 'fal fa-check-circle'
          } else {
            return 'fal fa-hourglass-half'
          }
        } else {
          return props.actionable ? 'fas fa-lock' : 'fal fa-hourglass-half'
        }
      } else if (!admin) {
        if (action && status !== 'approved' && status !== 'processed') {
          return 'fas fa-undo'
        } else {
          return 'fas fa-times-circle'
        }
      }
    })
    const actionText = computed(() => {
      let { approver, item: { status, action }, requireGrade } = props
      if (status === 'pending') {
        if (approver) {
          if (action === 'add') return 'Approve'
          else if (requireGrade) return 'Assign Grade'
          return 'Acknowledge'
        }
      }
    })
    const tooltip = computed(() => {
      let { admin, approver, item: { status, action } } = props
      if (status === 'pending') {
        if (admin && approver) {
          return (action === 'add' ? 'Approve' : 'Acknowledge') + ' this course change'
        } else {
          return 'Pending ' + (action === 'add' ? 'approval' : 'acknowledgement') + ' from the course instructor'
        }
      } else if (!admin) {
        if (action && status !== 'approved' && status !== 'processed') {
          return 'Undo schedule change'
        } else {
          return 'Remove class from schedule'
        }
      }
    })
    const action = computed(() => {
      let { admin, approver, item: { status, action } } = props
      if (action === false) return false
      if (status === 'pending') {
        if (admin && approver) {
          return 'approve'
        }
      } else if (!admin) {
        if (action && status !== 'approved' && status !== 'processed') {
          return 'undo'
        } else if (!admin) {
          return 'remove'
        }
      }
      return ''
    })
    const color = computed(() => {
      let { admin, approver, item: { status, action } } = props
      if (status === 'pending') {
        if (admin && approver) {
          return 'success'
        } else if (!admin) {
          return 'error'
        }
      } else if (action && status !== 'approved' && status !== 'processed') {
        return 'success'
      } else if (!admin) {
        return 'error'
      }
    })

    function edit () {
      if (confirm('Are you sure you want to remove the previous change so you can make further changes to this course?')) {
        emit('undo')
      }
    }

    return {
      user,
      roles,
      isRecords,
      icon,
      actionText,
      tooltip,
      action,
      color,
      edit
    }
  }
}
</script>
